import React from "react"

function TableLineShrimmer(props){

    var effect_push = [];
    for(var i=1; i <= props.item_count; i++){
        effect_push.push(
            <tr>
                <td><lines class="shine"></lines></td>
                <td className="red-text"><lines class="shine"></lines></td>            
                <td className="red-text"><lines class="shine"></lines></td>
                <td><lines class="shine"></lines></td>
            </tr>
        )
    }

    return(
        effect_push
    )
}

export default TableLineShrimmer;

