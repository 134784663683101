import React, {useState, useEffect} from "react"
import {Container,Table} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import dateFormat from 'dateformat';
import moment from "moment";
import {config} from '../Common/constant';
import {uniqueID} from '../../components/utils/common';
import TableLineShrimmer from '../shrimmer/TableLineShrimmer';
import {isLoggedIn, isBrowser, logout, getUser } from "../../services/auth"
import { submitPresentationStatus } from "../../queries/api_links";



const AppoinmentsTable = (props) => {

    const[sort_properties, setProperties] = useState();

    useEffect(()=>{
        let properties = props.AppntData && props.AppntData.length > 0 ? props.AppntData : [];
        let sorted_properties = [];

        if(props.type === "upcoming"){
            sorted_properties = properties.sort((a, b) => new Date(a.appointment.DateAndTimeOfTheAppointment) - new Date(b.appointment.DateAndTimeOfTheAppointment))
        } else{
            sorted_properties = properties.sort((a, b) => new Date(b.appointment.DateAndTimeOfTheAppointment) - new Date(a.appointment.DateAndTimeOfTheAppointment))
        }
        
        setProperties(sorted_properties);
    },[props.AppntData, props.type])

    const createLink = (property_id, seller_email, user_email, presentation_type, property_data) => {
        //console.log("property_data", property_data);
        
        if (isBrowser()) {
            localStorage.setItem('create_present', JSON.stringify({'property_id':property_id, 'seller_email': seller_email, 'user_email':user_email}));
        }
    }
    

    const submitData = async(property_id, present_type, present_status, uniqueID_1, appointment_id, seller_fact_find, uniqueID_2) => {

        const updateQry = submitPresentationStatus(property_id, present_type, present_status, uniqueID_1, appointment_id, seller_fact_find, uniqueID_2).then(res => {
            if(res && res.status === 200){
                console.log("success_msg", property_id+'-success');
            } else{

            }
        });
    }
    
    //console.log('loading',props.loading);
    var prevalPreview = '';
    var t = new Date(1614779021206);
    var formatted = dateFormat(moment(new Date(1615205146 * 1000)), "dd/mm/yy")+" at "+dateFormat(moment(new Date(1615205146 * 1000)), "UTC:h:MMtt");
    //console.log("formatted", formatted);

    //console.table("sortedActivities 1", sort_properties);

    return (
    <>

        <div className={props.className}>
            <h2>
                <strong>{props.HeadTitle}</strong>
                <span>{props.AppntCount}</span>
            </h2>
            <div className="table-wrap">

            <Table id={props.AppntTableId} className={`${props.AppntData && props.AppntData.length > 0 ? '' : 'no_rec_tbl_cls'}`} responsive>
                <thead>
                    <tr className="table-head">
                        <th>Date/Time</th>
                        <th>Property</th>
                        <th className="wdth3">Pre-Val Presentation</th>
                        <th className="wdth3">Custom Presentation</th>
                    </tr>
                </thead>
                <tbody>
                    {

                    props.loading ? (
                        <>
                            <TableLineShrimmer item_count={3} />
                        </>

                    ) : sort_properties && sort_properties.length > 0 ?  

                        sort_properties.map((property, index) => {

                        var uniqueID_1 = uniqueID();
                        var uniqueID_2 = uniqueID(); 

                        var DateAndTimeOfTheAppointment = property.appointment.DateAndTimeOfTheAppointment && moment(property.appointment.DateAndTimeOfTheAppointment).add(1, 'hour')

                        var appDateTime= DateAndTimeOfTheAppointment && dateFormat(DateAndTimeOfTheAppointment, "dd/mm/yy") +" at "+ dateFormat(DateAndTimeOfTheAppointment, "UTC:h:MMtt");

                        var appBooked= property.appointment.AppointmentBookedOn && dateFormat(property.appointment.AppointmentBookedOn, "dd/mm/yy") +" at "+ dateFormat(property.appointment.AppointmentBookedOn, "UTC:h:MMtt");

                        var appOpened= property.date_of_viewing && dateFormat(property.date_of_viewing, "dd/mm/yy") +" at "+ dateFormat(property.date_of_viewing, "UTC:h:MMtt");

                        var preval_status = property.preval_presentation_url;

                        var custom_presentation_agent_url = property.custom_presentation_agent_url;

                        //console.log("custom_preval_status", custom_preval_status);                      
                        
                        var previewUrl = config.presentation_preview+'/'+uniqueID_1+'-'+property.id;
                        var customAgentUrl = config.presentation_preview+'/'+uniqueID_2+'-'+property.id;

                        var seller_fact_find = {}, if_yes = '';

                        if(property.urls && (property.urls.PrevalPresentationURL && property.urls.CustomPresentationAgentURL)){                            
                            previewUrl = property.urls.PrevalPresentationURL;
                            customAgentUrl = property.urls.CustomPresentationAgentURL;
                        } else{
                            prevalPreview = submitData(property.id, 'preval', 'sent', uniqueID_1, property.appointment.Id, seller_fact_find, uniqueID_2);
                        }

                        //prevalPreview = submitData(property.id, 'preval', 'sent', uniqueID_1, property.appointment.Id, seller_fact_find);

                        
                        return(
                            <>
                            <tr>
                                <td>{appDateTime}</td>
                                <td className="red-text"><Link to={`${config.property_details}/${property.id}`} onClick={() => {createLink(property.id, property.seller_email, property.user_email)}}>{property.address}</Link></td>

                                <td className="red-text">                                    
                                    {<a href={`${previewUrl}`} className={`grey_color`} target="_blank">View</a>}
                                </td>            
                                <td className="red-text">
                                    {<a href={`${customAgentUrl}`} className={`grey_color`} target="_blank">View</a>}
                                </td>             
                            </tr>
                           </> )
                        }) : (props.noRecordsMsg && (<td colSpan={4} className="noRecMsg">{props.search_text ? "There are no records!" : props.noRecordsMsg}</td>))
                     
                    }                     
                </tbody>
            </Table>
            </div>
            {/* table-wrap */}
        </div>   
    </>
    )
}

export default AppoinmentsTable
