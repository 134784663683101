import React, {useEffect, useState} from "react"
import {Container,Table} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import { useContainerContext } from "../store/ContainerContext";
import AppoinmentsTable from "../components/modules/appoinment-table";
import SearchForm from "../components/modules/search-form";
import {isLoggedIn, isBrowser, logout, getUser } from "../services/auth"
import fetch from 'cross-fetch';
import moment from "moment";
import { APPOINMENTS } from "../queries/common_use_query";


import TimeTrackerComp from "../components/modules/time_tracker";
import { Offline, Online } from "react-detect-offline";

function DashboardPage(){

  const context = useContainerContext();
  const { logged_user_data } = context.state;
  const { userInfoDispatch } = context.actions;
  const [ search_text, setSearchText ] = useState('');
  const [ app_data, setAppData ] = useState('');
  const [ app_data2, setAppData2 ] = useState('');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }

  const logOut = () =>{
    logout();
    userInfoDispatch({});
    if(isBrowser){
      navigate(`/`);
    }    
  }

  const { loading, error, data } = APPOINMENTS(logged_user_data && logged_user_data.email, search_text);
  useEffect(() => {
    setAppData(data);
  }, [data])
  
  var upcom_app = [], past_app = [];


  return(
    <>
      <TimeTrackerComp />

      <SEO title="Dashboard" description="Dashboard" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Dashboard" />
        {/* sidebar */}

        <div className="dashboard-content">
          <Container>
              <div className="content-wrapper">
                <div className="top-content">
                    <h2><strong>Hello {logged_user_data && logged_user_data.First_Name ? logged_user_data.First_Name : logged_user_data.first_name}</strong><a href="javascript:;" onClick={logOut}><span>(Not you?)</span></a></h2>
                    
                    <SearchForm handlechange={handlechange} />

                </div>
                {/* top-content */}

                {
                  app_data && app_data.properties && app_data.properties.length > 0 && app_data.properties.map((property, index) => {
                    var DateAndTimeOfTheAppointment = property.appointment.DateAndTimeOfTheAppointment && moment(property.appointment.DateAndTimeOfTheAppointment).add(1, 'hour')

                    var app_date = new Date(DateAndTimeOfTheAppointment);
                    if(app_date > new Date()){
                      upcom_app.push(property)
                    } else{
                      past_app.push(property)
                    }
                    //upcom_app.push(property)
                  })
                }
  
                {/* ========== Upcoming Appoinments ======== */}
                <AppoinmentsTable HeadTitle="Upcoming Appointments" className="card-block" AppntCount={upcom_app.length} AppntTableId="upcoming_id" AppntData={upcom_app} noRecordsMsg={"Coming Soon"} type="upcoming" loading={loading} search_text={search_text} />


                {
                  past_app.length > 0 &&
                  <AppoinmentsTable HeadTitle="Past Appointments" className="card-block block-two" AppntCount={past_app.length} AppntTableId="past_id" AppntData={past_app} type="past" loading={loading} search_text={search_text} />                
                }
                {/* ========== Past Appoinments ======== */}
                

              </div>
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default DashboardPage
